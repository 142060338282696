


















































































import Loading from '@/shared/components/Loading.vue';
import { countriesAlpha3Codes } from '@/shared/models/geo.model';
import {
  FilterConditions,
  FilterInput,
  TransformFiltersToJson
} from '@/shared/services/filter/filter.service';
import { AccountManagementModule } from '@/store';
import { ToastHelper } from '@/utils/toast.util';
import { Component, Vue, Watch } from 'vue-property-decorator';

@Component({ components: { Loading } })
export default class AccountFilter extends Vue {
  accountModule = AccountManagementModule;
  loading = false;

  countryOptions = countriesAlpha3Codes;

  accFltrOptions = [
    { value: 'acctId', text: 'ACCT CODE' },
    { value: 'account', text: 'ACCT CODE NAME' },
    { value: 'active', text: 'STATUS' },
    { value: 'country', text: 'COUNTRY' },
    { value: 'userId', text: 'USER ID' },
    { value: 'contactName', text: 'CONTACT NAME' },
    { value: 'firstName', text: 'FIRST NAME' },
    { value: 'lastName', text: 'LAST NAME' },
    { value: 'email', text: 'EMAIL' },
    { value: 'phone', text: 'PHONE' },
    { value: 'alertsEmail', text: 'ALERTS EMAIL' },
    { value: 'reportsEmail', text: 'REPORTS EMAIL' },
    { value: 'supportEmail', text: 'SUPPORT EMAIL' }
  ];

  statusOptions = [
    { value: null, text: 'All' },
    { value: true, text: 'Active' },
    { value: false, text: 'Inactive' }
  ];

  filterBy = 'acctId';
  AccFilter = new FilterInput({
    condition: FilterConditions.Equal,
    search: null,
    field: this.filterBy
  });

  @Watch('filterBy', { immediate: true })
  onPropertyChanged(value: string, oldValue: string) {
    this.AccFilter = new FilterInput({
      condition: FilterConditions.Equal,
      search: null,
      field: this.filterBy
    });
  }

  async search() {
    this.loading = true;
    try {
      this.accountModule.setFilters(TransformFiltersToJson([this.AccFilter]));
      await this.accountModule.search();
    } catch (err) {
      ToastHelper.show('Account code search', err.message, 5000, 'danger');
    }

    this.loading = false;
  }

  resetFilters() {
    this.AccFilter = new FilterInput({
      condition: FilterConditions.Equal,
      search: null,
      field: 'acctId'
    });

    this.accountModule.setFilters(TransformFiltersToJson([this.AccFilter]));
    this.accountModule.load();
  }
}
