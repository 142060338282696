import { coupleMails } from '@/shared/validators/couple-mails';
import { helpers, maxLength, required } from 'vuelidate/lib/validators';
export const mail = helpers.regex(
  'mail',
  /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
);

export const name = helpers.regex('name', /^(?=.{1,40}$)[a-zA-Z]+(?:[-'\s][a-zA-Z]+)*$/);

export const telNumber = helpers.regex(
  'telNumber',
  /^(\([0-9]{3}\) |[0-9]{3}-)[0-9]{3}-[0-9]{4}$/
);

export const accountValidation: any = {
  language: {},
  systemMeasurement: {},
  country: {},
  timeZone: {},
  businessType: {},
  company: { required },
  firstName: { required, name },
  lastName: { required, name },
  email: { required, mail, maxLength: maxLength(256) },
  alertsEmail: { coupleMails, maxLength: maxLength(256) },
  reportsEmail: { coupleMails, maxLength: maxLength(256) },
  phone: { telNumber },
  address: {},
  modality: {},
  accountType: {},
  emailContent: {}
};

export const accountData = {
  language: 'EN',
  systemMeasurement: 'US',
  country: 'USA',
  timeZone: null,
  businessType: null,
  company: null,
  firstName: null,
  lastName: null,
  userId: null,
  phone: null,
  email: null,
  alertsEmail: null,
  reportsEmail: null,
  address: null,
  modality: null,
  accountType: 1,
  emailContent: null,
  active: true
};
