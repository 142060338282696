







































import { TableHeaders } from '@/shared/components/table/models';
import TmsTable from '@/shared/components/table/TmsTable.vue';
import { Component, Vue } from 'vue-property-decorator';
import { AccountListHeaders } from '@/pages/Admin/AccountManagement/models/AccountListHeaders';
import AccountFilter from './AccountFilter/AccountFilter.vue';
import Loading from '@/shared/components/Loading.vue';
import AccountModal from './AccountModal/AccountModal.vue';
import { AccountManagementModule } from '@/store';
import { ToastHelper } from '@/utils/toast.util';
import { AccountManagementService } from '@/shared/services/account-management/account-management.service';

@Component({
  components: { TmsTable, AccountFilter, Loading, AccountModal }
})
export default class AccountManagement extends Vue {
  accountManagementModule = AccountManagementModule;
  columns: TableHeaders[] = AccountListHeaders;
  loading = false;
  modalTitle = null;
  accountId = null;

  constructor() {
    super();
  }

  get tableData() {
    return this.accountManagementModule.accounts;
  }

  get isLastPage(): boolean {
    return false;
  }

  async created() {
    await this.accountManagementModule.load();
  }

  async action(type: { key: string; data: any }) {
    if (type.key === 'clientSite') {
      this.clientSite(type.data);
    } else if (type.key === 'edit') {
      await this.accountManagementModule.getAccountById(type.data.acctId);
      this.modalTitle = `${type.data.account}(${type.data.acctId}) - Account Details`;
      this.accountId = type.data.acctId;
      this.$bvModal.show('account-modal');
    }
  }
  onInputChange({ field, data }) {
    switch (field) {
      case 'active':
        this.changeActive(data);
        break;
      default:
        break;
    }
  }
  async changeActive(account: any) {
    try {
      await this.accountManagementModule.changeAccountActive(account);
    } catch (err) {
      console.log('User Status Change Error', err);
      ToastHelper.show('User', err.response.data?.error);
    }
  }

  clientSite(account: any) {
    localStorage['params'] = JSON.stringify({
      acctId: account.acctId,
      acctName: account.account
    });
    const win = window.open(`${window.location.origin}`, 'clientWebWin');
    win.focus();
  }
}
