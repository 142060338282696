
























































































































































































































































































































































import Loading from '@/shared/components/Loading.vue';
import { countriesAlpha3Codes } from '@/shared/models/geo.model';
import { timeZonesList } from '@/shared/models/timezone.model';
import { AccountManagementModule, DivisionModule } from '@/store';
import { convertDateFieldToAPIFormat } from '@/utils/date.util';
import { deepClone } from '@/utils/utils';
import { Component, Vue } from 'vue-property-decorator';
import { Prop } from 'vue-property-decorator';
import { validationMixin } from 'vuelidate';

import { accountData, accountValidation } from '../@types/account.model';
Component.registerHooks(['validations']);
@Component({
  components: {
    Loading
  },
  mixins: [validationMixin]
})
export default class AccountModal extends Vue {
  @Prop() accountId: any;

  loading = false;
  accountModule = AccountManagementModule;
  selectedAccount = this.accountModule.account;

  convertDateFieldToAPIFormat = convertDateFieldToAPIFormat;
  languageOptions = [
    { value: 'EN', text: 'English' },
    { value: 'ES', text: 'Español' }
  ];
  countryOptions = countriesAlpha3Codes;
  timeZoneOptions = timeZonesList;
  systemMeasurementOptions = [
    { value: 'US', text: 'US' },
    { value: 'EU', text: 'EU' }
  ];
  accountTypeOptions = [
    { value: 1, text: 'Billable Account' },
    { value: 2, text: 'Trial Account' },
    { value: 3, text: 'QA Account' }
  ];

  account = null;
  form = this.account;
  errorModal = false;

  masks = {
    telNumMask: [
      '(',
      /\d/,
      /\d/,
      /\d/,
      ') ',
      /\d/,
      /\d/,
      /\d/,
      '-',
      /\d/,
      /\d/,
      /\d/,
      /\d/
    ]
  };
  constructor() {
    super();
  }
  created() {
    if (this.accountId) {
      this.account =
        this.accountId && this.selectedAccount
          ? {
              ...this.selectedAccount
            }
          : { ...accountData };
    } else {
      this.account = { ...accountData };
    }
    this.form = this.account;
  }

  async createOrupdateAccount() {
    if (this.isFormInValid()) {
      return;
    }
    this.loading = true;
    try {
      const createAccount = deepClone(this.account);
      if (Boolean(createAccount.phone))
        createAccount.phone = createAccount.phone.replace(/\D/g, '').toString();
      createAccount.account = createAccount.company;
      await this.accountModule.createOrEditAccount(createAccount);
      this.$bvModal.hide('account-modal');
      this.loading = false;
    } catch {
      this.loading = false;
    }
  }

  closeAccount() {
    this.$bvModal.hide('account-modal');
  }

  validateState(val: any) {
    if (this.$v && this.$v.form && this.$v.form[val]) {
      const { $dirty, $error }: any = this.$v.form[val];
      return $dirty ? !$error : null;
    }
  }

  get requiredFields(): Set<string> {
    return new Set(
      Object.keys(accountValidation).filter(
        fieldName => accountValidation[fieldName].required
      )
    );
  }

  isFormInValid() {
    this.$v.form.$touch();
    if (this.$v.form.$anyError) {
      this.errorModal = true;
    }
    return this.$v.form.$anyError;
  }

  validations() {
    return {
      form: accountValidation
    };
  }

  validateNotMandatory(val: any) {
    if (this.$v && this.$v.form && this.$v.form[val]) {
      const { $dirty, $error }: any = this.$v.form[val];
      if (!this.account[val] || this.account[val].trim() == '') {
        return null;
      }
      return $dirty ? !$error : null;
    }
  }
}
