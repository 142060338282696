import {
  TableHeaders,
  CellContentType
} from '@/shared/components/table/models';

export const AccountListHeaders: TableHeaders[] = [
  { key: 'number', label: 'NO', tdClass: 'min-w-50' },
  {
    key: 'acctId',
    label: `AcctId`,
    sortable: true
  },
  {
    key: 'country',
    label: `Country`,
    sortable: true
  },
  {
    key: 'account',
    label: `Account`,
    sortable: true
  },
  {
    key: 'contactName',
    label: `Contact Name`,
    sortable: true
  },
  {
    key: 'phone',
    label: `Phone#`,
    sortable: true
  },
  {
    key: 'email',
    label: `Email`,
    sortable: true
  },
  {
    key: 'alertsEmail',
    label: `Email for Alerts`,
    sortable: true
  },
  {
    key: 'reportsEmail',
    label: `Email for Reports`,
    sortable: true
  },
  {
    key: 'driversCount',
    label: `#of Drivers`,
    sortable: true
  },
  {
    key: 'active',
    label: 'Status',
    content: { type: CellContentType.Switcher }
  },
  {
    key: 'edit',
    label: '',
    content: {
      type: CellContentType.Icon,
      label: 'Edit Account',
      icon: 'pencil'
    }
  }
  ,
  {
    key: 'clientSite',
    label: '',
    content: {
      type: CellContentType.Icon,
      label: 'Switch to account',
      icon: 'box-arrow-up-right'
    }
  }
];
